import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchLayout from '../../layouts/Search/SearchLayout';
import {
  useLazyAlbumsSearchQuery,
  useLazyArtistsSearchQuery,
  useGlobalSearchQuery,
  useLazyTracksSearchQuery,
} from '../../services/qobuz.service';
import { RootState } from '../../store/rootReducer';
import {
  getAllAlbumsFromQuerySelector,
  getAllAmbiancesFromQuerySelector,
  getAllArtistsFromQuerySelector,
  getAllPlaylistsFromQuerySelector,
  getAllTracksFromQuerySelector,
  getAllSoundDesignDtoWithIndependentPlaylistsFromQuerySelector,
  PAGINATION_MAX,
  PAGINATION_SIZE,
  getAllSpotsFromQuerySelector,
} from './selector';
import {
  buildTrackItemFromTrackDto,
  buildArtistCardFromArtistDto,
  buildAlbumCardItemFromAlbumDto,
  buildPlaylistCardItemFromPlaylistDto,
  buildAmbianceCardItemFromAmbianceDto,
  buildSoundDesignCardFromSoundDesignDto,
  buildSpotCardItemFromSpotDto,
} from './types';
import {
  useGetBlacklistsQuery,
  useLazyGetSoundDesignsWithIndependentPlaylistsQuery,
  useLazyGetSpotsQuery,
  useLazyGetUserPlaylistsQuery,
} from '../../services/api.service';
import { useVoteTrackHook } from '../hooks/useVoteTrack';
import {
  setConfirmPlaySpotModale,
  setInfoSoundLibraryUnavailableModaleIsOpen,
  setSpotBottomSheet,
  setTrackBottomSheet,
} from '../../store/modaleSlice';
import Drawer from '../Drawer/Drawer';
import useBackToProgConfirm from '../hooks/useBackToProgConfirm';
import {
  search,
  fetchMoreAlbums,
  fetchMoreArtists,
  fetchMorePlaylists,
  fetchMoreTracks,
  fetchMoreSpots,
} from '../../store/searchSlice';
import { useFeatureAvailable } from '../../utils/hooks/useFeatureAvailable';

const Search = ({ adminMode }: { adminMode: boolean }) => {
  const appState = useSelector((state: RootState) => state.AppReducer);

  const dispatch = useDispatch();
  const backToProg = useBackToProgConfirm();
  const searchState = useSelector((state: RootState) => state.SearchReducer);
  const isPlaySpotFeatureAvailable = useFeatureAvailable('playSpotFeature');
  const { data, isFetching } = useGlobalSearchQuery({ query: searchState.currentSearch });
  const [triggerArtistsSearch, { data: artistsData, isFetching: isArtistsFetching }] = useLazyArtistsSearchQuery();
  const [triggerAlbumsSearch, { data: albumsData, isFetching: isAlbumsFetching }] = useLazyAlbumsSearchQuery();
  const [triggerTracksSearch, { data: tracksData, isFetching: isTracksFetching }] = useLazyTracksSearchQuery();
  const [
    triggerPlaylistsSearch,
    { data: playlistsData, isFetching: isPlaylistsFetching, originalArgs: playlistsArgs },
  ] = useLazyGetUserPlaylistsQuery();
  const [triggerSpotsSearch, { data: spotsData, isFetching: isSpotsFetching, originalArgs: spotArgs }] =
    useLazyGetSpotsQuery();
  const { data: aggregatedBlacklists } = useGetBlacklistsQuery(appState.boxDetail?._id || '');
  const [triggerSoundDesignsWithIndependentPlaylists, { isFetching: isSoundDesignsFetching }] =
    useLazyGetSoundDesignsWithIndependentPlaylistsQuery();
  const allTracksQuery = useSelector((state: RootState) =>
    getAllTracksFromQuerySelector(state)(searchState.currentSearch),
  );
  const allAlbumsQuery = useSelector((state: RootState) =>
    getAllAlbumsFromQuerySelector(state)(searchState.currentSearch),
  );
  const allArtistsQuery = useSelector((state: RootState) =>
    getAllArtistsFromQuerySelector(state)(searchState.currentSearch),
  );
  const allPlaylistsQuery = useSelector((state: RootState) =>
    getAllPlaylistsFromQuerySelector(state)(searchState.currentSearch),
  );
  const allSpotsQuery = useSelector((state: RootState) =>
    getAllSpotsFromQuerySelector(state)(searchState.currentSearch),
  );
  const allAmbiancesQuery = useSelector((state: RootState) =>
    getAllAmbiancesFromQuerySelector(state)(searchState.currentSearch),
  );
  const allSoundDesignDtoWithIndependentPlaylists = useSelector((state: RootState) =>
    getAllSoundDesignDtoWithIndependentPlaylistsFromQuerySelector(state)(),
  );
  const votesList = useSelector((state: RootState) => state.PlayerReducer.votesList);
  const isEphemeral = useSelector(
    (state: RootState) =>
      state.PlayerReducer.params.currentPlaylistMode === 'ephemeral' &&
      state.PlayerReducer.params.tshokoStatus === 'start',
  );
  const params = useSelector((state: RootState) => state.PlayerReducer.params);
  const voteTrack = useVoteTrackHook();

  const searchEnabled = searchState.currentSearch !== '';

  const allTracks = searchEnabled
    ? allTracksQuery.query === searchState.currentSearch && allTracksQuery.tracks.items.length
      ? allTracksQuery.tracks.items
      : data?.tracks.items || []
    : [];
  const allAlbums = searchEnabled
    ? allAlbumsQuery.query === searchState.currentSearch && allAlbumsQuery.albums.items.length
      ? allAlbumsQuery.albums.items
      : data?.albums.items || []
    : [];
  const allArtists = searchEnabled
    ? allArtistsQuery.query === searchState.currentSearch && allArtistsQuery.artists.items.length
      ? allArtistsQuery.artists.items
      : data?.artists.items || []
    : [];

  const mostPopular = data && data.most_popular.items[0];

  useEffect(() => {
    if (adminMode) {
      triggerPlaylistsSearch({
        search: searchState.currentSearch,
        limit: PAGINATION_SIZE,
        offset: 0,
        personnalUserPlaylistsOnly: true,
        validOnly: true,
        sortBy: 'creationDate',
      });
      triggerSoundDesignsWithIndependentPlaylists();
      if (isPlaySpotFeatureAvailable && appState.boxDetail) {
        triggerSpotsSearch({
          search: searchState.currentSearch,
          limit: PAGINATION_SIZE,
          offset: 0,
        });
      }
    }
  }, [
    searchState.currentSearch,
    triggerPlaylistsSearch,
    adminMode,
    triggerSoundDesignsWithIndependentPlaylists,
    triggerSpotsSearch,
    isPlaySpotFeatureAvailable,
    appState.boxDetail,
  ]);

  useEffect(() => {
    if (params.isQobuzOffline) {
      dispatch(setInfoSoundLibraryUnavailableModaleIsOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.isQobuzOffline]);

  return params.isQobuzOffline ? (
    <SearchLayout
      isLoading
      adminMode={adminMode}
      onSearch={() => {}}
      searchValue=""
      onClickTrack={() => {}}
      onClickSpot={() => {}}
      onClickSpotCard={() => {}}
    />
  ) : (
    <>
      {searchEnabled || adminMode ? (
        <SearchLayout
          adminMode={adminMode}
          onSearch={(value) => dispatch(search(value))}
          searchValue={searchState.currentSearch}
          isLoading={isFetching}
          isTracksFetching={isTracksFetching}
          isAlbumsFetching={isAlbumsFetching}
          isArtistsFetching={isArtistsFetching}
          isPlaylistsFetching={isPlaylistsFetching}
          isAmbiancesFetching={isSoundDesignsFetching}
          isSpotsFetching={isSpotsFetching}
          loadDataPlaylists={
            (adminMode && searchState.currentSearch === playlistsArgs?.search
              ? (playlistsData?.length || 0) === searchState.playlistsOffset + PAGINATION_SIZE
              : false) &&
            searchState.playlistsOffset < PAGINATION_SIZE * PAGINATION_MAX &&
            !isPlaylistsFetching
              ? () => {
                  const newOffset =
                    searchState.currentSearch !== playlistsArgs?.search || !playlistsData
                      ? 0
                      : playlistsArgs.offset + PAGINATION_SIZE;
                  triggerPlaylistsSearch(
                    {
                      search: searchState.currentSearch,
                      limit: PAGINATION_SIZE,
                      offset: newOffset,
                      personnalUserPlaylistsOnly: true,
                      validOnly: true,
                      sortBy: 'creationDate',
                    },
                    true,
                  )
                    .unwrap()
                    .then(() => {
                      dispatch(fetchMorePlaylists(newOffset));
                    });
                }
              : undefined
          }
          loadDataTracks={
            (tracksData?.tracks.total && searchState.currentSearch === tracksData.query
              ? tracksData.tracks.total > searchState.tracksOffset + PAGINATION_SIZE
              : true) &&
            searchState.tracksOffset < PAGINATION_SIZE * PAGINATION_MAX &&
            !isTracksFetching
              ? () => {
                  const newOffset =
                    searchState.currentSearch !== tracksData?.query || !tracksData
                      ? PAGINATION_SIZE
                      : tracksData.tracks.offset + PAGINATION_SIZE;
                  triggerTracksSearch(
                    {
                      query: searchState.currentSearch,
                      limit: PAGINATION_SIZE,
                      offset: newOffset,
                    },
                    true,
                  )
                    .unwrap()
                    .then(() => {
                      dispatch(fetchMoreTracks(newOffset));
                    });
                }
              : undefined
          }
          loadDataArtists={
            (artistsData?.artists.total && searchState.currentSearch === artistsData.query
              ? artistsData.artists.total > searchState.artistsOffset + PAGINATION_SIZE
              : true) &&
            searchState.artistsOffset < PAGINATION_SIZE * PAGINATION_MAX &&
            !isArtistsFetching
              ? () => {
                  const newOffset =
                    searchState.currentSearch !== artistsData?.query || !artistsData
                      ? PAGINATION_SIZE
                      : artistsData.artists.offset + PAGINATION_SIZE;
                  triggerArtistsSearch(
                    {
                      query: searchState.currentSearch,
                      limit: PAGINATION_SIZE,
                      offset: newOffset,
                    },
                    true,
                  )
                    .unwrap()
                    .then(() => {
                      dispatch(fetchMoreArtists(newOffset));
                    });
                }
              : undefined
          }
          loadDataAlbums={
            (albumsData?.albums.total && searchState.currentSearch === albumsData.query
              ? albumsData.albums.total > searchState.albumsOffset + PAGINATION_SIZE
              : true) &&
            searchState.albumsOffset < PAGINATION_SIZE * PAGINATION_MAX &&
            !isAlbumsFetching
              ? () => {
                  const newOffset =
                    searchState.currentSearch !== albumsData?.query || !albumsData
                      ? PAGINATION_SIZE
                      : albumsData.albums.offset + PAGINATION_SIZE;
                  triggerAlbumsSearch(
                    {
                      query: searchState.currentSearch,
                      limit: PAGINATION_SIZE,
                      offset: newOffset,
                    },
                    true,
                  )
                    .unwrap()
                    .then(() => {
                      dispatch(fetchMoreAlbums(newOffset));
                    });
                }
              : undefined
          }
          loadDataSpots={
            (adminMode && searchState.currentSearch === spotArgs?.search
              ? (spotsData?.length || 0) === searchState.spotsOffset + PAGINATION_SIZE
              : false) &&
            searchState.spotsOffset < PAGINATION_SIZE * PAGINATION_MAX &&
            !isSpotsFetching &&
            isPlaySpotFeatureAvailable &&
            appState.boxDetail
              ? () => {
                  const newOffset =
                    searchState.currentSearch !== spotArgs?.search || !spotsData
                      ? 0
                      : spotArgs.offset + PAGINATION_SIZE;
                  triggerSpotsSearch({
                    search: searchState.currentSearch,
                    limit: PAGINATION_SIZE,
                    offset: newOffset,
                  })
                    .unwrap()
                    .then(() => {
                      dispatch(fetchMoreSpots(newOffset));
                    });
                }
              : undefined
          }
          searchResults={{
            playlists: allPlaylistsQuery.map((playlistDto) => buildPlaylistCardItemFromPlaylistDto(playlistDto)),
            ambiances: allAmbiancesQuery.map((ambianceDto) => buildAmbianceCardItemFromAmbianceDto(ambianceDto)),
            albums: allAlbums.map((albumDto) => buildAlbumCardItemFromAlbumDto(albumDto, aggregatedBlacklists)),
            artists: allArtists.map((artistDto) => buildArtistCardFromArtistDto(artistDto, aggregatedBlacklists)),
            tracks: allTracks.map((trackDto) =>
              buildTrackItemFromTrackDto(trackDto, { blacklists: aggregatedBlacklists, userVotes: votesList }),
            ),
            spots: allSpotsQuery.map((spot) => buildSpotCardItemFromSpotDto(spot)),
            mostPopular:
              !mostPopular || !searchEnabled
                ? undefined
                : mostPopular.type === 'albums'
                ? { item: buildAlbumCardItemFromAlbumDto(mostPopular.content, aggregatedBlacklists), type: 'albums' }
                : mostPopular.type === 'artists'
                ? { item: buildArtistCardFromArtistDto(mostPopular.content, aggregatedBlacklists), type: 'artists' }
                : {
                    item: buildTrackItemFromTrackDto(mostPopular.content, {
                      blacklists: aggregatedBlacklists,
                      userVotes: votesList,
                    }),
                    type: 'tracks',
                  },
          }}
          soundLibrary={{
            playlists: allPlaylistsQuery.map((playlistDto) => buildPlaylistCardItemFromPlaylistDto(playlistDto)),
            soundDesigns: allSoundDesignDtoWithIndependentPlaylists.map((soundDesignDtoWithIndependentPlaylists) =>
              buildSoundDesignCardFromSoundDesignDto(soundDesignDtoWithIndependentPlaylists),
            ),
            spots: allSpotsQuery.map((spot) => buildSpotCardItemFromSpotDto(spot)),
          }}
          onClickTrack={(track) => dispatch(setTrackBottomSheet(track))}
          onClickTrackVote={
            params.isJukeboxModeOn
              ? (track) => {
                  voteTrack(track);
                }
              : undefined
          }
          onClickSpot={(spot) => dispatch(setSpotBottomSheet(spot))}
          onClickSpotCard={(spot) =>
            dispatch(setConfirmPlaySpotModale({ spotId: spot.id, title: spot.title, duration: spot.duration }))
          }
          {...(isEphemeral
            ? {
                onBackToProgClick: () => {
                  backToProg();
                },
              }
            : {})}
        />
      ) : (
        <SearchLayout
          isLoading={isFetching}
          onSearch={(value: string) => dispatch(search(value))}
          adminMode={adminMode}
          onClickTrack={() => {}}
          onClickSpot={() => {}}
          onClickSpotCard={() => {}}
          searchValue=""
        />
      )}
    </>
  );
};

export default ({ adminMode }: { adminMode: boolean }) => {
  const root = useCallback(() => <Search adminMode={adminMode} />, [adminMode]);
  return <Drawer drawerRoot={root} />;
};
